import Vue from 'vue'
import Vuex from 'vuex'
import support from '@/plugins/support'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '2.0.1',
    submitMessage:"",
  
    tableFields:{
      site_visit: [
        {key:'temperature_c', label: "temperature_c",type:'numeric',optionstable:null}
      ],
      net_set:[
        {key:'set_num', label:'set_num', type:'numeric',optionstable:null},
        {key:'depth_start', label:'depth (m)', type:'numeric',optionstable:null},
        {key:'depth_stop', label:'depth (m)', type:'numeric',optionstable:null},
        {key:'flow_start', label:'start flow', type:'numeric',optionstable:null},
        {key:'flow_stop', label:'stop flow', type:'numeric',optionstable:null},
        {key:'gear', label:'gear', type:'text',optionstable:'gear'},
        {key:'start_longitude', label:'start longitude', type:'numeric',optionstable:null},
        {key:'start_latitude', label:'start latitude', type:'numeric',optionstable:null},
        {key:'stop_longitude', label:'stop longitude', type:'numeric',optionstable:null},
        {key:'stop_latitude', label:'stop latitude', type:'numeric',optionstable:null},
        {key:'comment', label:'comment', type:'text',optionstable:null}

      ],
      catch: [
        {key:'field_species', label: "field_species",type:"select",optionstable:"species"},
        {key:'official_species', label: "official species",type:"select",optionstable:"species"},
        {key:'age', label: "age",type:"select",optionstable:"age"},
        {key:'length', label: "length",type:"numeric",optionstable:null},
        {key:'size_class', label: "size class",type:"select",optionstable:"size_class"},
        {key:'mass_g', label: "mass(g)",type:"numeric",optionstable:null},
        {key:'sample_type',label:"sample_type",type:"select",optionstable:"sample_type"},
        {key:'sin', label: "sin",type:"text",optionstable:null},
        {key:'comment_post', label: "office comment",type:"text",optionstable:null}, 
        {key:'comment_field', label: "field comment",type:"text",optionstable:null},
        { key: 'edit', label: '',type:"edit"},
      ],
      tally: [
        {key:'species', label: "species",type:"select",optionstable:"species"},
        {key:'age', label: "age",type:"select",optionstable:"age"},
        {key:'size_class', label: "size class",type:"select",optionstable:"size_class"},
        {key: 'tally', label:'tally',type:"numeric",optionstable:null},
        {key:'comment_post', label: "office comment",type:"text",optionstable:null}, 
        {key:'comment_field', label: "field comment",type:"text",optionstable:null},
      ],
        lab_fish: [
        {key:'field_species', label: "field species",type:"select",optionstable:"species"},
        {key:'official_species', label: "official species",type:"select",optionstable:"species",},
        {key:'length', label: "length",type:"numeric",optionstable:null},
        {key:'weight', label: "mass(g)",type:"numeric",optionstable:null},
        {key:'dorsal_fin', label: "dorsal fin",type:"select",optionstable:"dorsal_fin"},
        {key:'anal_fin', label: "anal fin",type:"select",optionstable:"anal_fin"},
        {key:'adipose_fin', label: "adipose fin",type:"select",optionstable:"adipose_fin"},
        {key:'brachiostegols', label: "brachiostegols",type:"numeric",optionstable:null},
        {key:'sin', label: "sin",type:"text",optionstable:null},
        {key:'comment_post', label: "office comment",type:"text",optionstable:null}, 
        {key:'comment_field', label: "field comment",type:"text",optionstable:null},
        ],
        lab_fish_sample: [
          {key:'sin', label: "SIN ",type:"text",optionstable:null},
          {key:'sample_type',label:"sample_type",type:"select",optionstable:"sample_type"},
          {key:'sample_method',label:"sample_method",type:"select",optionstable:"sample_method"},
          {key:'sample_card',label:"sample card",type:"text",optionstable:null},
          {key:'sample_card_location',label:"sample_card_location",type:'numeric',optionstable:null},
          
        ]

    },
    dataTables:{
      site_visit:[],
      net_set:[],
      catch:[],
      tally:[],
      lab_fish:[],
      lab_fish_sample:[],

    },
    refTables:{site:[], 
      tributary:[],
        species:[],
        size_class:[],
        sample_type:[],
        age:[],
        anal_fin:[],
        dorsal_fin:[],
        gear:[],
        adipose_fin:[],
        sample_method:[],
        },
    views:{},
    visit_date:'',
    station:'',
    set_num:-1,
    tributary:'',
    current_catch:{},
    current_tally:{},
    current_net_set:{},
    current_site_visit:{},

  },
 // getters
 getters:{  
  tableFields: (state) => (tableName) => state.tableFields[tableName],
  dataCache: (state) => (tableName) => state.dataCache[tableName],
  tableData: (state) => (tableName) => state.dataTables[tableName],
  refTable: (state) => (tableName) => state.refTables[tableName],
  viewData: (state) => (tableName) => state.views[tableName],
  getCurrentRecord: (state) => (tableName) => state[`current_${tableName}`] ? state[`current_${tableName}`] : support.newRecord(tableName),
  refTables: (state) => state.refTables,
  dataTables: (state) => state.dataTables,
  appName: (state) => state.appName,
  attribute: (state) => (varname) => state[varname]
  },
mutations:{
  updateAttribute(state, payload){
    state[payload.attribute] = payload.value
    if (payload.attribute === 'current_site_visit') {
      this.commit("setSiteVisitRecord")
      }
    if (payload.attribute === 'set_num') {
      this.commit("setSiteVisitRecord")
      this.commit("setNetSet")
    }
      //if the final selector is getting set look up the visit record
    if (((payload.attribute === 'visit_date') && 
    (state.station !== '')) || ((payload.attribute === 'station') && 
    (state.visit_date !== ''))){
      support.getApiData('dataTables','site_visit', `site_visit/visit_date/${state.visit_date}/station//${state.station}`)
      .then((result) => {
        this.commit('updateAttribute',{attribute:'current_site_visit', value:result.length > 0 ? result[0] : support.newRecord('site_visit')
      })})
    }
  },

  setNetSet(state) {

     state.current_net_set = 
      state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.set_num)).length > 0 ? state.dataTables.net_set.filter((el) =>
      el.set_num === parseInt(state.set_num))[0] : support.newRecord('net_set')
    
//catch and tally records
      state.current_catch = support.newRecord('catch')
      let endpoint = `catch/visit_date/${state.visit_date}/station/${state.station}/set_num/${state.set_num}`
      support.getApiData('dataTables','catch',endpoint).then((result) => {

        state.dataTables.catch = result
      })

      state.current_tally = support.newRecord('tally')
      endpoint = `tally/visit_date/${state.visit_date}/station/${state.station}/set_num/${state.set_num}`
     
      support.getApiData('dataTables','tally',endpoint).then((result) => {

        state.dataTables.tally= result
      })
    
    },
  

  setSiteVisitRecord(state){
    let endpoint = `net_set/visit_date/${state.visit_date}/station/${state.station}/`
     
    support.getApiData('dataTables','net_set', endpoint)
    .then((result) => {
      let records = result.filter((el) => 
      el.station === state.current_site_visit.station &
      el.visit_date === state.current_site_visit.visit_date
      )
      state.dataTables.net_set = records
      this.commit('setNetSet')
  })
  endpoint = `lab_fish/visit_date/${state.visit_date}/station/${state.station}/`
     
    support.getApiData('dataTables','lab_fish', endpoint)

  },

  updateChildren(state, payload ) {
    state.dataTables[payload.childTable].map((item) => {
      if(item.parent_id === payload.parentID) {
        item[payload.childField] = payload.newValue
        support.writeRecordToLocalForage({tableName:payload.childTable, record:JSON.parse(JSON.stringify(item))})
      }
    })
  },
  
  refreshRecords(state, payload) {
    if (payload.tableType != null) {
      state[payload.tableType][payload.tableName] = payload.records;
    } else {
      state[payload.tableName] = payload.records;

    }
    //support.syncLocalForageFromStore(payload)
  },
 
  resetPrimary(state) {
    state.current_site_visit = support.newRecord('site_visit')
    state.station=''
    state.tributary=''
    state.visit_date=''
    state.set_num = 0
  },
},
  actions: {
  },
  modules: {
  }
})
