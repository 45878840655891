import axios from 'axios'
//import data from '../plugins/data'
import store from "../store"
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
//axios.defaults.withCredentials = true;


export default {
      async submit(payload, tableName, endpoint=null) {
        console.log("submit")
        console.log(payload)
        console.log(endpoint)
        if(endpoint === null){
          endpoint = `${tableName}/keys/p/`
        }
        return new Promise((resolve, reject) => {
        if (payload.length) {
          axios
            .post(endpoint, payload)
            .then(response => {
              // TODO: use this for a success toast
              if (response.status === 200) {
                let rowsInserted = 0;
                for (let i in response.data.message) {
                  if (
                    response.data.status[i] != "error" &&
                    response.data.message[i].search("inserted") > -1
                  ) {
                    rowsInserted = rowsInserted + 1;
                  }
                }
                if (rowsInserted) {
                  store.commit('updateAttribute', {attribute:'submitMessage',value:`${rowsInserted} rows inserted/updated.`})
          
                  resolve(payload)
                }else {
                  store.commit('updateAttribute', {attribute:'submitMessage',value:`${response.data.message}.`})
                }
              } else {
          
                store.commit('updateAttribute',{attribute:'submitMessage',value: "Error inserting/updating record"})
            
                reject(payload)
              }
            })
            .catch((err) => {
              console.log("error posting", err)
              reject(payload)});
        
        }
        
      })
    },
      getGUID(tableName){
        var currentdt = Math.round((new Date()).getTime() / 1000);
        return tableName + 'web_form_' + currentdt;
      },
      populateReferenceTables() {
        Object.keys(store.state.refTables).forEach(async key => {
          await this.getApiData('refTables',key)});
      },
      async getApiData(holder,varName, providedendpoint=null, days=null) {
       
        let payload = {tableName:varName, tableType:holder};
        var endpoint;
        return new Promise((resolve, reject) => {
           if(providedendpoint !== null) {
            endpoint = providedendpoint
          
           } else if (days !== null) {
            endpoint = `${varName}/time_interval/${days}`;
           } else {
            endpoint = `/${varName}/`;
           }
           return axios
            .get(endpoint)
            .then(
              response =>
                {payload['records'] = response.data.sort(
                  (a, b) => a.sequence - b.sequence
                )
                store.commit('refreshRecords', payload)
                resolve(payload['records'])
              } 
            )
            .catch(err => {
              console.log(err)
              reject()
            }
            )
          });
        },

        newRecord(tableName){
          if (tableName === 'site_visit') {
            return {station: '',
                  visit_date: '',
                  tributary: '',
                  investigators: "",
                  start_time: "",
                  temperature_c: "",
                  comments: "",
                  gear: "",
                  global_id: "",
                  created_ts: "",
                  editted_ts: "",
                  ts: "",
                }
            }
          else if (tableName === 'net_set'){
            return {
              visit_date: store.getters['attribute']('visit_date'),
              station: store.getters['attribute']('station'),
              set_num: store.getters['attribute']('set_num'),
              start_time: "",
              stop_time: "",
              flow_start: "",
              start_longitude:-1,
              start_latitude:-1,
              stop_longitude:-1,
              stop_latitude:-1,
              flow_stop: "",
              depth_start: "",
              depth_stop: "",
              comment: "",
              global_id: "",
              created_ts: "",
              editted_ts: ""
            }
          }
          else if (tableName === 'tally'){
            return {
              visit_date: store.getters['attribute']('visit_date'),
              station: store.getters['attribute']('station'),
              set_num: store.getters['attribute']('set_num'),
              size_class: "",
              species: "",
              age:"",
              tally:"",
              global_id: "",
              created_ts: "",
              editted_ts: "",
              ts: "",
              update_ts: "",
            }
          }
          else if (tableName === 'catch'){
            return {
              visit_date: store.getters['attribute']('visit_date'),
              station: store.getters['attribute']('station'),
              set_num: store.getters['attribute']('set_num'),
              field_species: "",
              official_species: "",
              age:"",
              length: "",
              size_class:"",
              weight: "",
              sample_type:"",
              sin: "",
              comment_post: "",
              comment_field: "",
              global_id: "",
              created_ts: "",
              editted_ts: "",
              ts: ""
            }
          }
          else if (tableName === 'lab_fish'){
            return {
              visit_date: store.getters['attribute']('visit_date'),
              station_name: store.getters['attribute']('station'),
        field_species: "",
        official_species: "",
        length: "",
        mass_g: "",
        dorsal_fin: "",
        anal_fin: "",
        adipose_fin: "",
        brachiostegols: "",
        sin: "",
        comment_post: "",
        comment_field: "",
        global_id: "",
        created_ts: "",
        editted_ts: "",
        ts: "",
        update_ts: "",
            }
          }
      },
  //saves a single record    
  async saveRecord(table,record, pi = null){
    record = this.addMandatory(table, record, pi)
    return new Promise((resolve, reject) => {
      this.submit(this.forceFieldTypes(table, [record]),table,)
      .then(resolve(record))
     
      .catch(reject(record))
    })
    
  },
  makeGlobalId(table){
    return `${table}_web_form_${Math.floor(Date.now() / 1000)}`
   },
  addMandatory(table, record,pi){
    if (pi != null){
      record.parent_id = pi
    }
    record.ts = ((record.ts != undefined) && (record.ts !== '')) ? record.ts : this.getLocalTimestamp(new Date())
    record.created_ts = ((record.created_ts != undefined) && (record.created_ts !== '')) ? record.created_ts : this.getLocalTimestamp(new Date())
    record.editted_ts = this.getLocalTimestamp(new Date())
    record.global_id = ((record.global_id === '') || (record.global_id === undefined)) ?
     this.makeGlobalId(table) :
     record.global_id
    return record
  },
  //Returns timestamp as formatted string
  getLocalTimestamp: function (e) {
    let ts = new Date(e);
    return `${ts.getFullYear()}-${String(ts.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(ts.getDate()).padStart(2, "0")} ${String(
      ts.getHours()
    ).padStart(2, "0")}:${String(ts.getMinutes()).padStart(2, "0")}:${String(
      ts.getSeconds()
    ).padStart(2, "0")}`;
  },
  forceFieldTypes(tableName, records) {
    let header = store.getters['tableFields'](tableName);
    for (let item in records) {
      try {
        records[item] = this.forceFieldType(header, records[item]);
      } catch (err) {
        console.log(err);
      }
    }
    return records;
  },
  forceFieldType(header, record) {
    for (let a in record) {
      if ((record[a] !== undefined) && ((record[a] == null) || (record[a].length < 1))) {
        delete record[a];
      }
      else if ((header != null) && (
        header
          .filter(function (el) {
            return el["type"] === "numeric";
          })
          .map((a) => a.key)
          .includes(a)
      )) {
        record[a] = Number(record[a]);
      }
      else if ((header != null) && (
        header
          .filter(function (el) {
            return el["type"] === "boolean";
          })
          .map((a) => a.key)
          .includes(a)
      )) {
        record[a] = String(record[a]);
      }
    }
    return record;
  },

    }