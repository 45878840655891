<!-- App.vue -->
<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <h2>Yukon Fish Data {{ $store.getters.attribute('version') }}</h2>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
       <b-toast
      title="Data Uploaded"
      variant="success"
      auto-hide-delay="2000"
      v-model="snackbar"
    >
    {{ $store.getters.attribute('submitMessage') }}
    </b-toast>
     <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/fishlab">FishLab</router-link> |
      <router-link to="/fish">Fish</router-link>
    </div>
      <router-view/>
     
    </v-main>

    <v-footer app color="primary" dark> Copyright © 2021, ABR </v-footer>
  </v-app>
</template>

<script>
import support from './plugins/support.js'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  async mounted () {

   support.populateReferenceTables()
  },
  computed:{
    snackbar: {
    get(){
      return this.$store.getters.attribute('submitMessage').length > 0
    },
    set(value){
      if (!value) {
        this.$store.state.submitMessage = ''
      }
    }
  }
  }
};
</script>
