<template>
  <div class="about">
    <h1>{{ project }}</h1>
    These pages are for proofing, editing and entering data related to the {{ project }}. 
    Each tab corresponds to data collected using either the Fishlab or Fish Data android apps. 
    Use the submit buttons to ensure your edits are sent to and saved in the ydfda database.
  </div>
</template>

<script>
  

  export default {
    name: 'Home',
    data(){
      return{
    project: 'Yukon Fisheries Project'}
    },
    components: {
      
    },
  }
</script>
